import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { RootState } from '../../../../store/store';
import LinearProgress from '@mui/material/LinearProgress';
import Skeleton from '@mui/material/Skeleton';
import parse from 'html-react-parser';
import { Check, Edit3, MoreVertical } from 'react-feather';
import { SortableItem } from './SortableItem';
import {
  calculateStudyProgress,
  calculateStudyTimeRemaining,
} from '../../../../utils/calculateStudyTime';
import { updateCurrentUserInfo } from '../../../../services/auth';
import { StudyPhase } from '../../../../types/Study';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import { updateStudySession } from '../../../../services/studySession';
import { setTodaySession } from '../../../../slices/todaySession/todaySessionSlice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SnoozeSession from '../../SnoozeSession';
import { hapticsImpactLight } from '../../../../utils/haptics';
import { Drag } from '../../../../assets/svgs/Drag';
import { enqueueSnackbar } from 'notistack';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { generateCustomStudySession } from '../../../../services/study';
import { useRefreshStudySessionAndContent } from '../../../../utils/refreshStudySession';
import RegenerateModal from '../../../Global/RegenerateModal';

interface SessionOverviewProps {
  isSwitchingExam?: boolean;
  isModifyingToday: boolean;
  setIsModifyingToday: (isModifyingToday: boolean) => void;
}

const SessionOverview: React.FC<SessionOverviewProps> = ({
  isSwitchingExam,
  isModifyingToday,
  setIsModifyingToday,
}) => {
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const blocks = useSelector((state: RootState) => state.blocks);
  const activeBlockId = useSelector(
    (state: RootState) => state.todaySession.activeBlockId
  );
  const activeBlock = useSelector(
    (state: RootState) => state.blocks.find((b) => b.id === activeBlockId)!
  );
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const preferredPhase = useSelector(
    (state: RootState) => state.auth.userInfo?.preferredStartPhase
  );
  const [currentProgress, setCurrentProgress] = useState(0);
  const [items, setItems] = useState<string[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isEditingSession, setIsEditingSession] = useState(false);
  const [showRegenModal, setShowRegenModal] = useState(false);

  const [customFlashcardCount, setCustomFlashcardCount] = useState(0);
  const [customQBankCount, setCustomQBankCount] = useState(0);

  const [sessionMenuRef, setSessionMenuRef] =
    React.useState<null | HTMLElement>(null);
  const sessionMenuOpen = Boolean(sessionMenuRef);
  const handleSessionMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSessionMenuRef(event.currentTarget);
  };
  const handleSessionMenuClose = () => {
    setSessionMenuRef(null);
  };

  const dispatch = useDispatch();
  const previousFirstItemRef = useRef<string | null>(null);

  const {
    isLoadingStudySessionAndContent,
    // isLoadedStudySessionAndContent,
    setIsLoadingStudySessionAndContent,
    setIsLoadedStudySessionAndContent,
    refreshStudySessionAndContent,
  } = useRefreshStudySessionAndContent();

  useEffect(() => {
    setCurrentProgress(calculateStudyProgress(todaySession, blocks));

    const updatedItems: string[] = [];

    if (primaryExam) {
      if (primaryExam.isUsingFlashcards) {
        updatedItems.push('flashcard');
      }
      if (primaryExam.isUsingQbank) {
        updatedItems.push('qbank');
      }
    }

    // Move preferred phase to the top if it exists
    if (preferredPhase) {
      const index = updatedItems.indexOf(preferredPhase.toLowerCase());
      if (index > 0) {
        updatedItems.splice(index, 1);
        updatedItems.unshift(preferredPhase.toLowerCase());
      }
    }

    setItems(updatedItems);
  }, [todaySession, blocks, primaryExam, preferredPhase]);

  useEffect(() => {
    if (items.length > 0) {
      const firstItem = items[0];
      if (previousFirstItemRef.current !== firstItem) {
        previousFirstItemRef.current = firstItem;
        updateUserPreferredStartingPhase(
          StudyPhase[firstItem as keyof typeof StudyPhase]
        );
        updateStudySessionPhase(
          StudyPhase[firstItem as keyof typeof StudyPhase]
        );
      }
    }
  }, [items]);

  useEffect(() => {
    setCustomFlashcardCount(todaySession?.flashcardCount);
    setCustomQBankCount(todaySession?.vignetteCount);
  }, [todaySession, isModifyingToday]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setIsDragging(false);

    if (over && active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.indexOf(String(active.id));
        const newIndex = prevItems.indexOf(String(over.id));

        return arrayMove(prevItems, oldIndex, newIndex);
      });
      enqueueSnackbar('Phase order updated.', { autoHideDuration: 3000 });
    }
  };

  const updateUserPreferredStartingPhase = async (phase: StudyPhase) => {
    const updateUser = await updateCurrentUserInfo({
      preferredStartPhase: phase,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const updateStudySessionPhase = async (phase: StudyPhase) => {
    if (
      todaySession &&
      !todaySession.isStarted &&
      todaySession.phase !== phase
    ) {
      const updatedSession = { ...todaySession, phase: phase };
      const response = await updateStudySession(
        todaySession.id,
        updatedSession
      );
      dispatch(setTodaySession(response));
    }
  };

  const renderFlashcardCount = () => {
    if (isSwitchingExam || isLoadingStudySessionAndContent) {
      return (
        <Skeleton
          sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
          variant="rectangular"
          animation="wave"
        >
          <div>000</div>
        </Skeleton>
      );
    }

    return (
      <div className="counts-wrapper">
        <div className={`counts ${isModifyingToday ? 'is-modifying' : ''}`}>
          <span>{todaySession?.completedFlashcardCount} of</span>{' '}
          {todaySession?.flashcardCount}
        </div>
        {isModifyingToday && (
          <input
            className="question-number-input"
            type="number"
            min={0}
            max={400}
            value={customFlashcardCount}
            onChange={(e) => setCustomFlashcardCount(Number(e.target.value))}
          />
        )}
      </div>
    );
  };

  const renderQBankCount = (totalVignetteCount: number) => {
    if (isSwitchingExam || isLoadingStudySessionAndContent) {
      return (
        <Skeleton
          sx={{ bgcolor: 'rgba(255,255,255,.05)', borderRadius: '.25rem' }}
          variant="rectangular"
          animation="wave"
        >
          <div>000</div>
        </Skeleton>
      );
    }
    return (
      <div className="counts-wrapper">
        <div className={`counts ${isModifyingToday ? 'is-modifying' : ''}`}>
          <span>{totalVignetteCount} of</span> {todaySession?.vignetteCount}
        </div>
        {isModifyingToday && (
          <input
            className="question-number-input"
            type="number"
            min={0}
            max={400}
            value={customQBankCount}
            onChange={(e) => setCustomQBankCount(Number(e.target.value))}
          />
        )}
      </div>
    );
  };

  const renderSessionOverviewBottom = () => {
    if (isModifyingToday) {
      return (
        <div className="session_modify-controls">
          <button
            className="button button--glass"
            onClick={() => setIsModifyingToday(false)}
            style={
              isLoadingStudySessionAndContent
                ? { opacity: '0', pointerEvents: 'none', visibility: 'hidden' }
                : {}
            }
          >
            Cancel
          </button>
          <button
            className="button button--glass"
            onClick={() => {
              if (todaySession?.isStarted) {
                setShowRegenModal(true);
              } else {
                handleSubmitCustomSession();
              }
            }}
            style={
              isLoadingStudySessionAndContent
                ? { opacity: '0', pointerEvents: 'none', visibility: 'hidden' }
                : {}
            }
          >
            Save
          </button>
        </div>
      );
    } else {
      return (
        <>
          <div className="session_progress">
            <div className="session_progress_bar">
              <LinearProgress
                className="progress-bar"
                sx={{ width: '100%', minWidth: '13.8rem' }}
                variant="determinate"
                color="success"
                value={currentProgress}
              />
            </div>
            <div className="time-estimate" style={{ paddingTop: '.25rem' }}>
              {parse(calculateStudyTimeRemaining(todaySession, blocks))}
              <span className="helper">Est. Remaining</span>
            </div>
          </div>
        </>
      );
    }
  };

  const renderFlashcardDisplay = () => (
    <>
      {todaySession?.completedFlashcardCount ===
        todaySession?.flashcardCount && <Check className="check" />}
      {todaySession?.phase === StudyPhase.flashcard &&
        todaySession?.completedFlashcardCount !==
          todaySession?.flashcardCount && (
          <Tooltip title={'Current phase'}>
            <div className="dot"></div>
          </Tooltip>
        )}
      <div className="label">Flashcards</div>
      {renderFlashcardCount()}
    </>
  );

  const renderQBankDisplay = () => {
    let totalVignetteCount = 0;
    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i].isSubmitted) {
        totalVignetteCount += blocks[i].vignetteCount;
      } else {
        totalVignetteCount += Object.keys(blocks[i].answers).length;
      }
    }
    return (
      <div className="qbank-row">
        <div className="qbank-row_top">
          {totalVignetteCount === todaySession?.vignetteCount && (
            <Check className="check" />
          )}
          {todaySession?.phase === StudyPhase.qbank &&
            totalVignetteCount !== todaySession?.vignetteCount && (
              <Tooltip title={'Current phase'}>
                <div className="dot"></div>
              </Tooltip>
            )}
          <div className="label">QBank</div>
          {renderQBankCount(totalVignetteCount)}
        </div>
        <div className="qbank-row_bottom">
          {currentUser && (
            <Select
              labelId="block-type-selector-label"
              id="block-type-selector"
              value={currentUser.preferredBlockType}
              onChange={(e) => {
                const newValue = e.target.value;
                handleBlockTypeChange(newValue);
              }}
              onClick={hapticsImpactLight}
            >
              <MenuItem value="tutor">Untimed</MenuItem>
              <MenuItem value="timed">Timed</MenuItem>
            </Select>
          )}
        </div>
      </div>
    );
  };

  const handleBlockTypeChange = async (blockType: string) => {
    const updateUser = await updateCurrentUserInfo({
      preferredBlockType: blockType,
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
    let messageText = '';
    if (activeBlock?.isStarted) {
      messageText =
        'Block type updated. This change will take effect on the next block.';
    } else {
      messageText = 'Block type updated.';
    }
    enqueueSnackbar(messageText, { autoHideDuration: 3000 });
  };

  const handleSubmitCustomSession = async () => {
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await generateCustomStudySession(customFlashcardCount, customQBankCount);
    await refreshStudySessionAndContent();
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
    setIsModifyingToday(false);
  };

  return (
    <>
      <div className={`session ${isEditingSession ? 'is-editing' : ''}`}>
        <div className="session_top">
          <h3>
            {isModifyingToday ? "Modify Today's Session" : "Today's Session"}
          </h3>
          <button
            id="session-menu-button"
            className="button button--icon-only button--session-menu"
            aria-controls={sessionMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={sessionMenuOpen ? 'true' : undefined}
            onClick={handleSessionMenuClick}
          >
            <MoreVertical />
          </button>
          <button
            className="button button--icon-only button--modify"
            onClick={() => setIsModifyingToday(true)}
            style={
              isModifyingToday
                ? { opacity: '0', pointerEvents: 'none', visibility: 'hidden' }
                : { marginLeft: 'auto' }
            }
          >
            <Edit3 />
          </button>
        </div>
        <div className={`session_schedule ${isDragging ? 'is-dragging' : ''}`}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {items.map((item, index) => {
                return (
                  <SortableItem
                    key={item}
                    id={item}
                    index={index}
                    only={items.length === 1}
                  >
                    {item === 'flashcard'
                      ? renderFlashcardDisplay()
                      : renderQBankDisplay()}
                  </SortableItem>
                );
              })}
            </SortableContext>
          </DndContext>
        </div>
        {renderSessionOverviewBottom()}
        <Menu
          className="session-menu"
          id="basic-menu"
          anchorEl={sessionMenuRef}
          open={sessionMenuOpen}
          onClose={handleSessionMenuClose}
          MenuListProps={{
            'aria-labelledby': 'session-menu-button',
          }}
        >
          <MenuItem onClick={handleSessionMenuClose}>
            <button
              className="button button--edit-session"
              onClick={() => {
                hapticsImpactLight();
                setIsModifyingToday(true);
              }}
            >
              <Edit3 />
              <span>Modify Session</span>
            </button>
          </MenuItem>
          <MenuItem onClick={handleSessionMenuClose}>
            <SnoozeSession />
          </MenuItem>
          {items.length !== 1 && (
            <MenuItem onClick={handleSessionMenuClose}>
              <button
                className="button button--edit-session"
                onClick={() => {
                  hapticsImpactLight();
                  setIsEditingSession(true);
                }}
              >
                <Drag />
                <span>Rearrange Phases</span>
              </button>
            </MenuItem>
          )}
        </Menu>
      </div>
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={handleSubmitCustomSession}
        type={'modify-today'}
      />
    </>
  );
};

export default SessionOverview;
