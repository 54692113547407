import { useEffect, useState, useRef } from 'react';
import ContentSelection from '../../ContentSelection';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import moment from 'moment';
import { addOrUpdateUserExam } from '../../../../services/users';
import posthog from 'posthog-js';
import { useSnackbar } from 'notistack';
import { setUserInfo } from '../../../../slices/auth/authSlice';
import RegenerateModal from '../../RegenerateModal';
import {
  maxFlashcards,
  maxVignettes,
  minFlashcards,
  minVignettes,
} from '../../../../utils/contentQuantityUtils';
import CustomModal from '../../CustomModal';

interface WorkloadSelectProps {
  setIsLoadingStudySessionAndContent: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent: (isLoaded: boolean) => void;
  refreshStudySessionAndContent: () => void;
}

const WorkloadSelect: React.FC<WorkloadSelectProps> = ({
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const primaryExam = currentUserExams.find((exam) => exam.primary) || null;
  const primaryExamId = primaryExam?.id || '';

  const [
    confirmedTotalUserSelectedFlashcards,
    setConfirmedTotalUserSelectedFlashcards,
  ] = useState(primaryExam?.selectedFlashcardCount || 0);
  const [
    confirmedTotalUserSelectedVignettes,
    setConfirmedTotalUserSelectedVignettes,
  ] = useState(primaryExam?.selectedVignetteCount || 0);
  const [confirmedIsUsingFlashcards, setConfirmedIsUsingFlashcards] =
    useState<boolean>(primaryExam?.isUsingFlashcards || true);
  const [confirmedIsUsingQbank, setConfirmedIsUsingQbank] = useState<boolean>(
    primaryExam?.isUsingQbank || true
  );
  const [confirmedContentSelectionOption, setConfirmedContentSelectionOption] =
    useState('');
  const [contentSelectionOption, setContentSelectionOption] = useState('');
  const [workloadSelectionModalIsOpen, setWorkloadSelectionModalIsOpen] =
    useState(false);
  const [isUsingFlashcards, setIsUsingFlashcards] = useState<boolean>(
    primaryExam?.isUsingFlashcards || true
  );
  const [isUsingQbank, setIsUsingQbank] = useState<boolean>(
    primaryExam?.isUsingQbank || true
  );
  const [totalUserSelectedFlashcards, setTotalUserSelectedFlashcards] =
    useState(primaryExam?.selectedFlashcardCount || 0);
  const [totalUserSelectedVignettes, setTotalUserSelectedVignettes] = useState(
    primaryExam?.selectedVignetteCount || 0
  );
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [showRegenModal, setShowRegenModal] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  // Update User Exam Data when Exam Changes
  useEffect(() => {
    if (primaryExam) {
      const flashcards = primaryExam.selectedFlashcardCount;
      const vignettes = primaryExam.selectedVignetteCount;
      const usingFlashcards = primaryExam.isUsingFlashcards;
      const usingQbank = primaryExam.isUsingQbank;

      setConfirmedTotalUserSelectedFlashcards(flashcards);
      setConfirmedTotalUserSelectedVignettes(vignettes);
      setConfirmedIsUsingFlashcards(usingFlashcards);
      setConfirmedIsUsingQbank(usingQbank);

      // Initialize editing values to confirmed values
      setTotalUserSelectedFlashcards(flashcards);
      setTotalUserSelectedVignettes(vignettes);
      setIsUsingFlashcards(usingFlashcards);
      setIsUsingQbank(usingQbank);
    }
  }, [primaryExamId]);

  // Compute content selection option for confirmed values
  useEffect(() => {
    const minV = minVignettes(primaryExam?.name);
    const maxV = maxVignettes(primaryExam?.name);
    const minF = minFlashcards(primaryExam?.name);
    const maxF = maxFlashcards(primaryExam?.name);

    if (
      confirmedIsUsingFlashcards &&
      confirmedIsUsingQbank &&
      confirmedTotalUserSelectedVignettes === minV &&
      confirmedTotalUserSelectedFlashcards === minF
    ) {
      setConfirmedContentSelectionOption('High Yield');
    } else if (
      confirmedIsUsingFlashcards &&
      confirmedIsUsingQbank &&
      confirmedTotalUserSelectedVignettes === maxV &&
      confirmedTotalUserSelectedFlashcards === maxF
    ) {
      setConfirmedContentSelectionOption('Comprehensive');
    } else if (
      confirmedIsUsingFlashcards &&
      confirmedIsUsingQbank &&
      confirmedTotalUserSelectedVignettes === (maxV - minV) / 2 + minV &&
      confirmedTotalUserSelectedFlashcards === (maxF - minF) / 2 + minF
    ) {
      setConfirmedContentSelectionOption('Recommended');
    } else {
      setConfirmedContentSelectionOption('Custom');
    }
  }, [
    primaryExam,
    confirmedIsUsingFlashcards,
    confirmedIsUsingQbank,
    confirmedTotalUserSelectedFlashcards,
    confirmedTotalUserSelectedVignettes,
  ]);

  // Compute content selection option for editing values
  useEffect(() => {
    const minV = minVignettes(primaryExam?.name);
    const maxV = maxVignettes(primaryExam?.name);
    const minF = minFlashcards(primaryExam?.name);
    const maxF = maxFlashcards(primaryExam?.name);

    if (
      isUsingFlashcards &&
      isUsingQbank &&
      totalUserSelectedVignettes === minV &&
      totalUserSelectedFlashcards === minF
    ) {
      setContentSelectionOption('High Yield');
    } else if (
      isUsingFlashcards &&
      isUsingQbank &&
      totalUserSelectedVignettes === maxV &&
      totalUserSelectedFlashcards === maxF
    ) {
      setContentSelectionOption('Comprehensive');
    } else if (
      isUsingFlashcards &&
      isUsingQbank &&
      totalUserSelectedVignettes === (maxV - minV) / 2 + minV &&
      totalUserSelectedFlashcards === (maxF - minF) / 2 + minF
    ) {
      setContentSelectionOption('Recommended');
    } else {
      setContentSelectionOption('Custom');
    }
  }, [
    primaryExam,
    isUsingFlashcards,
    isUsingQbank,
    totalUserSelectedFlashcards,
    totalUserSelectedVignettes,
  ]);

  // Determine if there are unsaved changes
  useEffect(() => {
    if (
      primaryExam &&
      (primaryExam.selectedVignetteCount !== totalUserSelectedVignettes ||
        primaryExam.selectedFlashcardCount !== totalUserSelectedFlashcards ||
        primaryExam.isUsingFlashcards !== isUsingFlashcards ||
        primaryExam.isUsingQbank !== isUsingQbank)
    ) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [
    primaryExam,
    totalUserSelectedFlashcards,
    totalUserSelectedVignettes,
    isUsingFlashcards,
    isUsingQbank,
  ]);

  const saveChangesOrShowConfirmation = async () => {
    if (todaySession.isStarted === false) {
      submitChanges(true);
    } else if (todaySession.isComplete) {
      submitChanges(false);
    } else {
      setShowRegenModal(true);
    }
  };

  const assignExamToUser = async (regenerate: boolean) => {
    if (!currentUser || !primaryExam) return;

    if (isUsingFlashcards !== primaryExam?.isUsingFlashcards) {
      posthog?.capture('flashcards_turn_' + (isUsingFlashcards ? 'on' : 'off'));
    }
    if (isUsingQbank !== primaryExam?.isUsingQbank) {
      posthog?.capture('qbank_turn_' + (isUsingQbank ? 'on' : 'off'));
    }

    const response = await addOrUpdateUserExam(
      currentUser.id,
      primaryExamId,
      // @ts-expect-error date type issue
      primaryExam.examDate,
      totalUserSelectedFlashcards,
      totalUserSelectedVignettes,
      isUsingFlashcards,
      isUsingQbank,
      primaryExam?.hasCompletedRealExam || false,
      primaryExam?.realExamScore || null,
      false,
      regenerate
    );

    const updatedUser = { ...currentUser };
    updatedUser.exams = response;
    dispatch(setUserInfo(updatedUser));

    // Refresh study session and content
    if (regenerate) {
      await refreshStudySessionAndContent();
    }

    enqueueSnackbar('Exam updated successfully.', {
      autoHideDuration: 3000,
      style: { width: 'fit-content' },
    });

    if (document.activeElement && document.activeElement !== document.body) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  const submitChanges = async (regenerate: boolean) => {
    setWorkloadSelectionModalIsOpen(false);
    setIsLoadingStudySessionAndContent(true);
    setIsLoadedStudySessionAndContent(false);
    await assignExamToUser(regenerate);
    setConfirmedTotalUserSelectedFlashcards(totalUserSelectedFlashcards);
    setConfirmedTotalUserSelectedVignettes(totalUserSelectedVignettes);
    setConfirmedIsUsingFlashcards(isUsingFlashcards);
    setConfirmedIsUsingQbank(isUsingQbank);
    setIsLoadingStudySessionAndContent(false);
    setIsLoadedStudySessionAndContent(true);
  };

  const setWorkloadOption = (option: string) => {
    if (option === 'High Yield') {
      setTotalUserSelectedVignettes(minVignettes(primaryExam?.name));
      setTotalUserSelectedFlashcards(minFlashcards(primaryExam?.name));
      setIsUsingFlashcards(true);
      setIsUsingQbank(true);
    } else if (option === 'Recommended') {
      setTotalUserSelectedVignettes(
        (maxVignettes(primaryExam?.name) - minVignettes(primaryExam?.name)) /
          2 +
          minVignettes(primaryExam?.name)
      );
      setTotalUserSelectedFlashcards(
        (maxFlashcards(primaryExam?.name) - minFlashcards(primaryExam?.name)) /
          2 +
          minFlashcards(primaryExam?.name)
      );
      setIsUsingFlashcards(true);
      setIsUsingQbank(true);
    } else if (option === 'Comprehensive') {
      setTotalUserSelectedVignettes(maxVignettes(primaryExam?.name));
      setTotalUserSelectedFlashcards(maxFlashcards(primaryExam?.name));
      setIsUsingFlashcards(true);
      setIsUsingQbank(true);
    }
  };

  const handleUnsavedChanges = () => {
    if (cancelButtonRef.current) {
      cancelButtonRef.current.classList.add('shake');
    }
    if (submitButtonRef.current) {
      submitButtonRef.current.classList.add('shake');
    }
    setTimeout(() => {
      if (cancelButtonRef.current) {
        cancelButtonRef.current.classList.remove('shake');
      }
      if (submitButtonRef.current) {
        submitButtonRef.current.classList.remove('shake');
      }
    }, 500);
  };

  return (
    <>
      <button
        className="button button--glass button--workload-selection"
        onClick={() => {
          setTotalUserSelectedFlashcards(confirmedTotalUserSelectedFlashcards);
          setTotalUserSelectedVignettes(confirmedTotalUserSelectedVignettes);
          setIsUsingFlashcards(confirmedIsUsingFlashcards);
          setIsUsingQbank(confirmedIsUsingQbank);
          setWorkloadSelectionModalIsOpen(true);
        }}
      >
        <div className="button--workload-selection_label">Workload</div>
        <div className="button--workload-selection_value">
          {confirmedContentSelectionOption}
        </div>
      </button>
      {primaryExam && (
        <CustomModal
          open={workloadSelectionModalIsOpen}
          onClose={() => {
            setTotalUserSelectedFlashcards(
              confirmedTotalUserSelectedFlashcards
            );
            setTotalUserSelectedVignettes(confirmedTotalUserSelectedVignettes);
            setIsUsingFlashcards(confirmedIsUsingFlashcards);
            setIsUsingQbank(confirmedIsUsingQbank);
            setWorkloadSelectionModalIsOpen(false);
          }}
          name={'workload-selection'}
          glass={true}
          unsavedChanges={unsavedChanges}
          handleUnsavedChanges={handleUnsavedChanges}
        >
          <div className="modal_header">
            <div className="exam-title">
              <span>{primaryExam?.name}</span>
              <h2>Workload Settings</h2>
            </div>
          </div>
          <div className="modal_content">
            <ContentSelection
              // @ts-expect-error contentSelection type issue
              contentSelectionOption={contentSelectionOption}
              primaryExamName={primaryExam.name}
              primaryExamDate={moment(primaryExam.examDate).format(
                'YYYY-MM-DD'
              )}
              isUsingFlashcards={isUsingFlashcards}
              setIsUsingFlashcards={setIsUsingFlashcards}
              totalUserSelectedFlashcards={totalUserSelectedFlashcards}
              setTotalUserSelectedFlashcards={setTotalUserSelectedFlashcards}
              isUsingQbank={isUsingQbank}
              setIsUsingQbank={setIsUsingQbank}
              totalUserSelectedVignettes={totalUserSelectedVignettes}
              setTotalUserSelectedVignettes={setTotalUserSelectedVignettes}
              setWorkloadOption={setWorkloadOption}
            />
          </div>
          <div className="modal_actions">
            <button
              className="button button--glass"
              onClick={() => {
                setTotalUserSelectedFlashcards(
                  confirmedTotalUserSelectedFlashcards
                );
                setTotalUserSelectedVignettes(
                  confirmedTotalUserSelectedVignettes
                );
                setIsUsingFlashcards(confirmedIsUsingFlashcards);
                setIsUsingQbank(confirmedIsUsingQbank);
                setWorkloadSelectionModalIsOpen(false);
              }}
              ref={cancelButtonRef}
            >
              Cancel
            </button>
            <button
              ref={submitButtonRef}
              className="button button--glass"
              onClick={saveChangesOrShowConfirmation}
              disabled={
                primaryExam?.selectedVignetteCount ===
                  totalUserSelectedVignettes &&
                primaryExam?.selectedFlashcardCount ===
                  totalUserSelectedFlashcards &&
                primaryExam?.isUsingFlashcards === isUsingFlashcards &&
                primaryExam?.isUsingQbank === isUsingQbank
              }
            >
              Confirm
            </button>
          </div>
        </CustomModal>
      )}
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={submitChanges}
        type={'exam-settings'}
      />
    </>
  );
};

export default WorkloadSelect;
