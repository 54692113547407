import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../../slices/auth/authSlice';
import { ArrowRight, User, X } from 'react-feather';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { hapticsImpactLight } from '../../../utils/haptics';
import {
  initUserForStudying,
  resetStudyProgress,
} from '../../../services/study';
import { useRefreshStudySessionAndContent } from '../../../utils/refreshStudySession';

interface SettingsMenuProps {
  mobileMenuIsOpen: boolean;
  setMobileMenuIsOpen: (isOpen: boolean) => void;
}

const SettingsMenu: React.FC<SettingsMenuProps> = ({
  mobileMenuIsOpen,
  setMobileMenuIsOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [activeSection, setActiveSection] = useState('');
  const notifButtonRef = useRef(null);

  const { refreshStudySessionAndContent } = useRefreshStudySessionAndContent();

  useEffect(() => {
    if (activeSection === '') {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_menu button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    } else {
      const firstFocusableElement = document.querySelector(
        '.settings-menu_body_sections section button'
      ) as HTMLButtonElement;
      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [activeSection]);

  const handleResetProgress = async () => {
    try {
      const response = await resetStudyProgress();
      if (response.success) {
        await initUserForStudying();

        // Refresh today's study session and content
        await refreshStudySessionAndContent();

        enqueueSnackbar(
          'User progress reset successfully. Please reload the page.',
          {
            autoHideDuration: 3000,
          }
        );
      } else {
        enqueueSnackbar('Error resetting user progress.');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error resetting user progress.');
    }
  };

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/auth');
  };

  return (
    <div
      className={`settings-menu ${mobileMenuIsOpen ? 'open' : ''} ${activeSection !== '' ? 'open-sub' : ''}`}
    >
      <div
        className={`icon ${mobileMenuIsOpen ? 'open' : ''}`}
        ref={notifButtonRef}
      >
        <User />
      </div>
      <div className="settings-menu_menu">
        <div className="settings-menu_menu_header">
          <h2>Settings</h2>
          <button
            onClick={() => {
              setMobileMenuIsOpen(false);
              hapticsImpactLight();
            }}
          >
            <X />
          </button>
        </div>
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            navigate('/account');
            hapticsImpactLight();
          }}
        >
          <span>Account</span>
          <ArrowRight />
        </button>
        <button
          className={`settings-menu_menu_item ${activeSection === 'roadmap' ? 'active' : ''}`}
          onClick={() => {
            navigate('/roadmap');
            hapticsImpactLight();
          }}
        >
          <span>Roadmap</span>
          <ArrowRight />
        </button>
        {currentUser?.role === 'admin' && !currentUser?.demoMode && (
          <Link
            to={'/admin'}
            className={`settings-menu_menu_item ${activeSection === 'admin' ? 'active' : ''}`}
            style={{ textDecoration: 'none' }}
            onClick={hapticsImpactLight}
          >
            <span>Admin Dashboard</span>
          </Link>
        )}
        <button
          className={`settings-menu_menu_item ${activeSection === 'account' ? 'active' : ''}`}
          onClick={() => {
            window.Intercom('showNewMessage');
            hapticsImpactLight();
          }}
        >
          Help
        </button>
        {currentUser?.role === 'admin' && !currentUser?.demoMode && (
          <button
            className="settings-menu_menu_item"
            onClick={() => {
              handleResetProgress();
            }}
          >
            <span>Reset Progress</span>
          </button>
        )}
        <button
          className="settings-menu_menu_item"
          onClick={() => {
            handleSignOut();
            hapticsImpactLight();
          }}
        >
          <span>Log Out</span>
        </button>
      </div>
      {activeSection !== '' && (
        <button
          className="settings-menu_overlay"
          onClick={() => {
            setActiveSection('');
            hapticsImpactLight();
          }}
        ></button>
      )}
      {/* <NotificationFeed
        isVisible={notificationsIsVisible}
        setIsVisible={setNofiticationsIsVisible}
        notifButtonRef={notifButtonRef}
      /> */}
    </div>
  );
};

export default SettingsMenu;
