import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { changePassword, updateCurrentUserInfo } from '../../services/auth';
import { useSnackbar } from 'notistack';
import {
  logout,
  setUserInfo,
  updateAuthToken,
  updateRefreshToken,
} from '../../slices/auth/authSlice';
import { deleteUser } from '../../services/users';
import { AlertTriangle, ExternalLink } from 'react-feather';
import GoogleIcon from '../../assets/google-g.svg';
import AppleIcon from '../../assets/apple.svg';
import { hapticsImpactLight } from '../../utils/haptics';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import OraSwitch from '../../components/CustomMUI/Switch';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { renderHint } from '../../utils/renderHint';
import { TriangleRight } from '../../assets/svgs/TriangleRight';
import { TriangleDown } from '../../assets/svgs/TriangleDown';
import Page from '../../components/Page';
import { useHotkeys } from 'react-hotkeys-hook';
import { useUpdateTheme } from '../../hooks/useUpdateTheme';
import CustomModal from '../../components/Global/CustomModal';
import posthog from 'posthog-js';

const Account: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const currentAuth = useSelector((state: RootState) => state.auth);

  const [dangerZoneModalIsOpen, setDangerZoneModalIsOpen] = useState(false);
  const [dangerZoneType, setDangerZoneType] = useState('');
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [darkMode, setDarkMode] = useState(currentUser?.darkMode || false);
  const [demoMode, setDemoMode] = useState(currentUser?.demoMode || false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordChangeError, setPasswordChangeError] = useState('');
  const [editName, setEditName] = useState(currentUser?.name || '');
  // const [selectedTimezone, setSelectedTimezone] = useState(
  //   currentUser?.timezone
  // );

  useHotkeys(
    'esc',
    () => {
      if (!dangerZoneModalIsOpen) {
        navigate('/');
      }
    },
    {},
    [dangerZoneModalIsOpen]
  );

  const { updateTheme } = useUpdateTheme();

  useHotkeys('t', () => handleDarkModeChange(!currentUser?.darkMode), {
    keyup: true,
  });

  useEffect(() => {
    const blocks = document.querySelectorAll('.block--settings');
    blocks.forEach((block, index) => {
      setTimeout(() => {
        block.classList.add('block--settings--visible');
      }, 250 * index);
    });
    return () => {
      blocks.forEach((block) => {
        block.classList.remove('block--settings--visible');
      });
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleSaveName = async () => {
    const updateUser = await updateCurrentUserInfo({ name: editName });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
      enqueueSnackbar('Name updated successfully.', { autoHideDuration: 3000 });
      posthog?.capture('user_updated_name');
    } else {
      enqueueSnackbar(
        'Error updating name. Please reload the page and try again.'
      );
    }
  };

  // const handleUpdateTimezone = async () => {
  //   const updateUser = await updateCurrentUserInfo({
  //     timezone: selectedTimezone,
  //   });
  //   if (updateUser) {
  //     dispatch(setUserInfo(updateUser));
  //     enqueueSnackbar('Timezone updated successfully.', {
  //       autoHideDuration: 3000,
  //     });
  //   } else {
  //     enqueueSnackbar(
  //       'Error updating timezone. Please reload the page and try again.'
  //     );
  //   }
  // };

  const handleDangerZoneButton = (type: string) => {
    setDangerZoneModalIsOpen(true);
    setDangerZoneType(type);
  };

  const handleDangerZoneAction = async (type: string) => {
    if (type === 'delete' && dangerZoneType === 'delete') {
      if (currentUser?.id) {
        const response = await deleteUser(currentUser?.id);
        if (response?.status === 204) {
          posthog?.capture('user_deleted_account');
          handleSignOut();
        } else {
          enqueueSnackbar(
            'Error deleting account. Please reload the page and try again.'
          );
        }
      }
    }
    setDangerZoneModalIsOpen(false);
  };

  const determineChangePasswordValid = () => {
    if (
      newPassword === confirmNewPassword &&
      newPassword.length >= 8 &&
      oldPassword !== newPassword
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangePassword = async () => {
    const response = await changePassword(
      currentAuth.authToken as string,
      oldPassword,
      newPassword
    );
    if (response && response.newToken && response.refreshToken) {
      handleChangePasswordSuccess(response.newToken, response.refreshToken);
    } else {
      if (response.error) {
        setPasswordChangeError(response.error);
      }
    }
  };

  const handleChangePasswordSuccess = (
    authToken: string,
    refreshToken: string | null = null
  ) => {
    enqueueSnackbar('Password updated successfully.', {
      autoHideDuration: 3000,
    });
    setIsChangingPassword(false);
    setOldPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    dispatch(updateAuthToken(authToken));
    if (refreshToken) dispatch(updateRefreshToken(refreshToken));
  };

  const updateDemoMode = async (demoMode: boolean) => {
    const updateUser = await updateCurrentUserInfo({ demoMode: demoMode });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  const handleSignOut = () => {
    dispatch(logout());
    navigate('/auth');
  };

  const handleDarkModeChange = (darkMode: boolean) => {
    setDarkMode(darkMode);
    posthog?.capture('user_changed_theme', { darkMode: darkMode });
    updateTheme(darkMode);
  };

  const handleDemoModeChange = (demoMode: boolean) => {
    setDemoMode(demoMode);
    updateDemoMode(demoMode);
  };

  return (
    <Page>
      <div className="account">
        <h1 className="m-t-0">Account</h1>
        <div className="block block--settings">
          <div className="block_body">
            <h3>Display Name</h3>
            <div className="field">
              <TextField
                id="name"
                variant="outlined"
                value={editName}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setEditName(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="block_footer">
            <div className="helper">Please use up to 32 characters.</div>
            <button
              className="button button--glass"
              onClick={() => {
                handleSaveName();
                hapticsImpactLight();
              }}
              disabled={editName === currentUser?.name}
            >
              Save
            </button>
          </div>
        </div>
        <div className="block block--settings">
          <div className="block_body">
            <h3>Email</h3>
            <div className="field">
              <div className="value">{currentUser?.email}</div>
            </div>
          </div>
        </div>
        <div className="block block--settings">
          <div className="block_body">
            <h3>Password</h3>
            {!isChangingPassword && (
              <>
                {currentUser?.oauth && (
                  <p>
                    Your password is managed by {currentUser?.oauthStrategy}.
                  </p>
                )}
                {!currentUser?.oauth && <div>···········</div>}
              </>
            )}
            {isChangingPassword && (
              <div>
                {passwordChangeError && (
                  <p style={{ color: 'var(--error)' }}>{passwordChangeError}</p>
                )}
                <p>Change your password</p>
                <TextField
                  className="m-y-0-75"
                  id="oldPassword"
                  label="Current Password"
                  variant="outlined"
                  value={oldPassword}
                  type="password"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOldPassword(event.target.value);
                  }}
                />
                <TextField
                  className="m-y-0-75"
                  id="newPassword"
                  label="New Password"
                  variant="outlined"
                  value={newPassword}
                  type="password"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewPassword(event.target.value);
                  }}
                />
                <TextField
                  className="m-y-0-75"
                  id="confirmNewPassword"
                  label="Confirm Password"
                  variant="outlined"
                  value={confirmNewPassword}
                  type="password"
                  fullWidth
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmNewPassword(event.target.value);
                  }}
                />
              </div>
            )}
          </div>
          <div className="block_footer">
            {!currentUser?.oauth && (
              <>
                {!isChangingPassword && (
                  <button
                    className="button button--glass"
                    onClick={() => {
                      setIsChangingPassword(true);
                      hapticsImpactLight();
                    }}
                  >
                    Update
                  </button>
                )}
                {isChangingPassword && (
                  <button
                    className="button button--glass"
                    onClick={() => {
                      setIsChangingPassword(false);
                      hapticsImpactLight();
                    }}
                  >
                    Cancel
                  </button>
                )}
                {isChangingPassword && (
                  <button
                    className="button button--glass"
                    onClick={() => {
                      handleChangePassword();
                      hapticsImpactLight();
                    }}
                    disabled={determineChangePasswordValid()}
                  >
                    Update
                  </button>
                )}
              </>
            )}
            {currentUser?.oauth && (
              <>
                {currentUser?.oauthStrategy === 'Google' && (
                  <a
                    className="button button--glass"
                    style={{ width: 'fit-content' }}
                    href="https://myaccount.google.com/signinoptions/password"
                    target="_blank"
                    onClick={hapticsImpactLight}
                  >
                    <img src={GoogleIcon} alt="Google Logo" />
                    Manage Password
                    <ExternalLink
                      style={{ height: '1rem', marginLeft: '.125rem' }}
                    />
                  </a>
                )}
                {currentUser?.oauthStrategy === 'Apple' && (
                  <a
                    className="button button--glass"
                    style={{ width: 'fit-content' }}
                    href="https://support.apple.com/en-us/101567#:~:text=Change%20your%20Apple%20ID%20password%20on%20your%20iPhone%2C%20iPad%2C%20Apple,and%20confirm%20the%20new%20password."
                    target="_blank"
                    onClick={hapticsImpactLight}
                  >
                    <img src={AppleIcon} alt="Apple Logo" />
                    Manage Password
                    <ExternalLink
                      style={{ height: '1rem', marginLeft: '.125rem' }}
                    />
                  </a>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="block block--settings block--settings--notifications">
          <div className="block_body">
            <h3>
              <span>Emails &amp; Reminders</span>
              <OraSwitch checked={true} />
            </h3>
            <div className="email-reminders">
              <div className="email-reminders_options">
                <div className="email-reminders_options_option">
                  <span>Study Reminders</span>
                  <OraSwitch checked={true} />
                </div>
                <div className="email-reminders_options_option">
                  <span>Tips &amp; Tricks</span>
                  <OraSwitch checked={true} />
                </div>
                <div className="email-reminders_options_option">
                  <span>Product Updates</span>
                  <OraSwitch checked={true} />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="block block--settings">
          <div className="block_body">
            <h3>Display</h3>
            <h4>Theme</h4>
            <div
              className="field"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                justifyContent: 'space-between',
              }}
            >
              <InputLabel>Dark Mode</InputLabel>
              <OraSwitch
                checked={darkMode}
                onChange={(e) => handleDarkModeChange(e.target.checked)}
                onClick={() => {
                  renderHint(
                    'theme',
                    'Next time, press T to switch your theme.'
                  );
                  hapticsImpactLight();
                }}
              />
            </div>
            {currentUser?.role === 'admin' && (
              <div
                className="field m-t-1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                  justifyContent: 'space-between',
                }}
              >
                <InputLabel id="timezone-label">Demo Mode</InputLabel>
                <OraSwitch
                  checked={demoMode}
                  onChange={(e) => handleDemoModeChange(e.target.checked)}
                  onClick={() => {
                    hapticsImpactLight();
                  }}
                />
              </div>
            )}
            {/* <div className="field">
                    <InputLabel id="timezone-label" className="m-t-1-50 m-b-1">
                      Timezone
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="timezone-label"
                      id="timezone"
                      value={selectedTimezone}
                      onChange={(e) =>
                        setSelectedTimezone(e.target.value as string)
                      }
                      onClick={hapticsImpactLight}
                    >
                      {Object.keys(supportedTimezones).map((timezone, index) => {
                        return (
                          <MenuItem key={index} value={timezone}>
                            {supportedTimezones[timezone]}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div> */}
          </div>
        </div>
        <Accordion>
          <AccordionSummary
            aria-controls="adv-study-content"
            id="adv-study-header"
            sx={{ padding: 0 }}
          >
            <div className="row">
              <div className="icon">
                <TriangleRight classes="expand-icon expand-icon--closed" />
                <TriangleDown classes="expand-icon expand-icon--open" />
              </div>
              <span className="button button--link--glass p-l-0">
                Advanced Account Settings
              </span>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="block block--settings block--danger">
              <div className="block_body">
                <h3>Delete Account</h3>
                <p className="m-b-1-50">
                  This action fully deletes your account including your
                  scheduling data. You will be logged out. This cannot be
                  undone.
                </p>
                <button
                  className="button button--error"
                  onClick={() => handleDangerZoneButton('delete')}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <CustomModal
        open={dangerZoneModalIsOpen}
        onClose={() => setDangerZoneModalIsOpen(false)}
        name="danger-zone"
        glass={true}
      >
        <div className="modal_header">
          {dangerZoneType === 'reset' && <h2>Reset Scheduling</h2>}
          {dangerZoneType === 'delete' && <h2>Delete Account</h2>}
        </div>
        <div className="modal_content">
          {dangerZoneType === 'reset' && (
            <p>
              <AlertTriangle
                style={{
                  color: 'var(--error',
                  height: '1rem',
                  transform: 'translateY(2px)',
                }}
              />
              <strong style={{ color: 'var(--error' }}>
                PROCEED WITH CAUTION
              </strong>
              <br />
              <br />
              This action wipes all your scheduling data. This resets your study
              progress to zero. This cannot be undone.
            </p>
          )}
          {dangerZoneType === 'delete' && (
            <p>
              <AlertTriangle
                style={{
                  color: 'var(--error',
                  height: '1rem',
                  transform: 'translateY(2px)',
                }}
              />
              <strong style={{ color: 'var(--error' }}>
                PROCEED WITH CAUTION
              </strong>
              <br />
              <br />
              This action fully deletes your account including your scheduling
              data. You will be logged out. This cannot be undone.
            </p>
          )}
        </div>
        <div className="modal_actions">
          <button
            className="button button--secondary"
            onClick={() => {
              setDangerZoneModalIsOpen(false);
              hapticsImpactLight();
            }}
          >
            Cancel
          </button>
          <button
            className="button button--error"
            onClick={() => {
              handleDangerZoneAction('delete');
              hapticsImpactLight();
            }}
          >
            {dangerZoneType === 'reset' ? 'Reset Scheduling' : 'Delete Account'}
          </button>
        </div>
      </CustomModal>
    </Page>
  );
};

export default Account;
