import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import parse from 'html-react-parser';
import { Ora } from '../../../assets/svgs/Ora';
import useWindowWidth from '../../../hooks/useWindowWidth';
import posthog from 'posthog-js';
import { ArrowRight, ChevronRight } from 'react-feather';
import { joyrideStyles } from '../../../utils/joyrideStyles';
import CustomModal from '../../Global/CustomModal';

interface NonAuthFeatureTourProps {
  activeQuestionIndex: number;
  answeredCorrectly: boolean;
  stepTargets: (React.RefObject<HTMLElement> | string)[];
  stepFunctions: (() => void)[];
  run: boolean;
  stepIndex: number;
  onStepChange: (newIndex: number) => void;
  onTourEnd?: () => void;
  createAccountModalIsOpen: boolean;
  setCreateAccountModalIsOpen: (isOpen: boolean) => void;
}

const NonAuthFeatureTour: React.FC<NonAuthFeatureTourProps> = ({
  activeQuestionIndex,
  answeredCorrectly,
  stepTargets,
  stepFunctions,
  run,
  stepIndex,
  onStepChange,
  onTourEnd,
  createAccountModalIsOpen,
  setCreateAccountModalIsOpen,
}) => {
  const [steps, setSteps] = useState<Step[]>([]);
  const [ready, setReady] = useState(false);

  const windowWidth = useWindowWidth();

  const placementsDesktop = ['right', 'right', 'left'];
  const placementsMobile = ['center', 'center', 'center'];

  const renderContents = (index: number) => {
    if (index === 0) {
      const text =
        'This is the highest-yield topic for Step.<br/><br/>Every question is designed by USMLE experts and vetted by doctors.<br/><br/>Answer this question to see what makes Ora special.';
      return parse(text);
    }
    if (index === 1) {
      const textRight =
        'Nice work! Ora is already building a blueprint of your knowledge.<br/><br/>Every day, Ora assigns the highest-yield topics where you need the most support. By test day, you&#39;ll learn all 1,500 topics.';
      const textWrong =
        'Good try! Ora is already building a blueprint of your knowledge.<br/><br/>Every day, Ora assigns the highest-yield topics where you need the most support. By test day, you&#39;ll learn all 1,500 topics.';
      if (answeredCorrectly) {
        return parse(textRight);
      } else {
        return parse(textWrong);
      }
    }
    if (index === 2) {
      const text =
        'Ora automatically adds flashcards to your deck when you get a question wrong.<br/><br/>Ora flashcards use spaced repetition powered by machine learning. Complete your flashcards to learn the basics with active recall.';
      return parse(text);
    }
  };

  useEffect(() => {
    const areTargetsReady = stepTargets.every((target) => {
      if (typeof target === 'string') {
        return true;
      } else {
        return target.current !== null;
      }
    });

    if (areTargetsReady) {
      // @ts-expect-error - TS doesn't know about all step props
      const newSteps: Step[] = stepTargets.map((target, index) => {
        const stepTarget =
          typeof target === 'string' ? target : target.current!;
        return {
          target: stepTarget,
          placement:
            windowWidth > 900
              ? placementsDesktop[index]
              : placementsMobile[index],
          disableBeacon: true,
          content: (
            <div className="tour-wrapper">
              <div className="tour-wrapper_header">
                <div className="step-number">
                  {index + 1} <span>of 3</span>
                </div>
                <div className="steps">
                  {[...Array(3)].map((_, i) => (
                    <div
                      key={i}
                      className={`steps_step ${i === index ? 'active' : ''}`}
                    ></div>
                  ))}
                </div>
              </div>
              <div className="tour-wrapper_content">
                {renderContents(index)}
              </div>
              <div className="blur-1"></div>
              <div className="blur-2"></div>
              <div className="tour-wrapper_footer">
                {index === 0 && (
                  <button
                    onClick={() => {
                      onStepChange(1);
                      const floater = document.querySelector(
                        '.__floater'
                      ) as HTMLDivElement;
                      if (floater) {
                        floater.style.display = 'none';
                      }
                    }}
                    className="button button--glass"
                  >
                    Got It
                  </button>
                )}
                {index === 1 && (
                  <button
                    onClick={() => onStepChange(2)}
                    className="button button--glass"
                  >
                    Next
                  </button>
                )}
                {index === 2 && (
                  <>
                    <button
                      onClick={() => {
                        if (onTourEnd) {
                          onTourEnd();
                        }
                      }}
                      className="button button--glass"
                    >
                      Got It
                    </button>
                    <a
                      className="button button--glass button--free-account"
                      href="/auth?signup=true"
                      onClick={() => {
                        posthog?.capture('ms_demo_create_account', {
                          isLastStep: true,
                        });
                      }}
                    >
                      Create Free Account Now
                      <ChevronRight className="icon" />
                      <ArrowRight className="hover-icon" />
                    </a>
                  </>
                )}
              </div>
            </div>
          ),
        };
      });
      setSteps(newSteps);
      setReady(true);
    }
  }, [stepTargets, onStepChange, onTourEnd]);

  useEffect(() => {
    if (activeQuestionIndex !== 0) {
      if (onTourEnd) {
        onTourEnd();
      }
    }
  }, [activeQuestionIndex, onStepChange]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, type, status } = data;
    if (type === 'step:before') {
      if (stepFunctions[index]) {
        stepFunctions[index]();
      }
    }
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (onTourEnd) {
        onTourEnd();
      }
    }
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton={true}
        hideBackButton={true}
        disableScrolling={true}
        disableOverlay
        // @ts-expect-error - TS doesn't know about all joyride props
        styles={joyrideStyles}
      />
      <CustomModal
        open={createAccountModalIsOpen}
        onClose={() => setCreateAccountModalIsOpen(false)}
        name={'create-account'}
      >
        <div className="modal_content">
          <Ora />
          <p>Create an account now to continue learning with Ora.</p>
          <div className="actions">
            <a
              className="button button--glass button--create-account"
              href="/auth?signup=true"
              onClick={() => {
                posthog?.capture('ms_demo_create_account', {
                  isCreateAccountModal: true,
                });
              }}
            >
              Create Account
            </a>
            <div>Free. No sign-up required.</div>
          </div>
        </div>
        <div className="blur-1"></div>
        <div className="blur-2"></div>
      </CustomModal>
    </>
  );
};

export default NonAuthFeatureTour;
