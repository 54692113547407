import React, { useState, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import parse from 'html-react-parser';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { joyrideStyles } from '../../../utils/joyrideStyles';
import { RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentUserInfo } from '../../../services/auth';
import { setUserInfo } from '../../../slices/auth/authSlice';

interface HomeFeatureTourProps {
  stepTargets: (React.RefObject<HTMLElement> | string)[];
  stepFunctions: (() => void)[];
  run: boolean;
  stepIndex: number;
  onStepChange: (newIndex: number) => void;
  onTourEnd?: () => void;
}

const HomeFeatureTour: React.FC<HomeFeatureTourProps> = ({
  stepTargets,
  stepFunctions,
  run,
  stepIndex,
  onStepChange,
  onTourEnd,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const [steps, setSteps] = useState<Step[]>([]);
  const [ready, setReady] = useState(false);
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const placementsDesktop = ['bottom', 'right'];
  const placementsMobile = ['center', 'center'];

  const renderContents = (index: number) => {
    if (index === 0) {
      const text = 'This is the overview for today&apos;s study session.';
      return parse(text);
    }
    if (index === 1) {
      const text = 'Let&apos;s get started! Click Start to begin your session.';
      return parse(text);
    }
  };

  useEffect(() => {
    if (stepIndex === 0) {
      handleSeenHomeTour();
    }
  }, [stepIndex]);

  useEffect(() => {
    const areTargetsReady = stepTargets.every((target) => {
      if (typeof target === 'string') {
        return true;
      } else {
        return target.current !== null;
      }
    });
    if (areTargetsReady) {
      // @ts-expect-error - TS doesn't know about all step props
      const newSteps: Step[] = stepTargets.map((target, index) => {
        const stepTarget =
          typeof target === 'string' ? target : target.current!;
        return {
          target: stepTarget,
          placement:
            windowWidth > 900
              ? placementsDesktop[index]
              : placementsMobile[index],
          disableBeacon: true,
          content: (
            <div className="tour-wrapper">
              <div className="tour-wrapper_header">
                <div className="step-number">
                  {index + 1} <span>of 2</span>
                </div>
                <div className="steps">
                  {[...Array(2)].map((_, i) => (
                    <div
                      key={i}
                      className={`steps_step ${i === index ? 'active' : ''}`}
                    ></div>
                  ))}
                </div>
              </div>
              <div className="tour-wrapper_content">
                {renderContents(index)}
              </div>
              <div className="blur-1"></div>
              <div className="blur-2"></div>
              <div className="tour-wrapper_footer">
                {index === 0 && (
                  <button
                    onClick={() => {
                      onStepChange(1);
                      const floater = document.querySelector(
                        '.__floater'
                      ) as HTMLDivElement;
                      if (floater) {
                        floater.style.display = 'none';
                      }
                    }}
                    className="button button--glass"
                  >
                    Next
                  </button>
                )}
                {index === 1 && <></>}
              </div>
            </div>
          ),
        };
      });
      setSteps(newSteps);
      setReady(true);
    }
  }, [stepTargets, onStepChange, onTourEnd]);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { index, type, status } = data;

    if (type === 'step:before') {
      if (stepFunctions[index]) {
        stepFunctions[index]();
      }
    }

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      if (onTourEnd) {
        onTourEnd();
      }
    }
  };

  const handleSeenHomeTour = async () => {
    if (!currentUser) return;
    const updateUser = await updateCurrentUserInfo({
      onboardingTips: {
        ...currentUser?.onboardingTips,
        'home-tour': true,
      },
    });
    if (updateUser) {
      dispatch(setUserInfo(updateUser));
    }
  };

  if (!ready) {
    return null;
  }
  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous
        hideCloseButton={true}
        hideBackButton={true}
        disableScrolling={true}
        disableOverlay
        // @ts-expect-error - TS doesn't know about all joyride props
        styles={joyrideStyles}
      />
    </>
  );
};

export default HomeFeatureTour;
