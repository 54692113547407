import axiosInstance from './axiosCreator';

interface FlashcardUpdateData {
  front: string;
  back: string;
  explanation: string;
  versionNum?: number;
}

export const getVignettesFromFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.get(
      `/flashcards/${flashcardId}/vignettes`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Fetch Flashcard Content Completion
export const fetchFlashcardCompletion = async (
  userId: string,
  examId: string
) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/flashcardContentCompletion`,
      {
        userId: userId,
        examId: examId,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Mark a flashcard as priority or not
export const markPriorityFlashcards = async (
  flashcards: Array<{ flashcardId: string; isPriority: boolean }>
) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/priority`,
      flashcards
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Flashcard by ID
export const updateFlashcardBE = async (
  id: string,
  data: {
    flashcardYield: number | null;
    firstSessionOrd: number | null;
    exams: string[];
  }
) => {
  try {
    const response = await axiosInstance.put(`/flashcards/parent/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Flashcard Variant by ID
export const updateFlashcardVariantBE = async (
  variantId: string,
  data: FlashcardUpdateData
) => {
  try {
    const response = await axiosInstance.put(
      `/flashcards/variant/${variantId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Get all user suspended flashcards
export const getSuspendedFlashcards = async () => {
  try {
    const response = await axiosInstance.get(`/flashcards/suspended`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// User suspend a flashcard
export const suspendFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/${flashcardId}/suspend`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// User unsuspend a flashcard
export const unsuspendFlashcard = async (flashcardId: string) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/${flashcardId}/unsuspend`
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Update Vignette Global Suspend Status
export const updateFlashcardSuspendStatus = async (
  id: string,
  data: { isSuspended: boolean }
) => {
  try {
    const response = await axiosInstance.put(
      `/flashcards/${id}/global-suspend`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Set Flashcard isQAed
export const setFlashcardQAStatus = async (id: string, isQAed: boolean) => {
  try {
    const response = await axiosInstance.put(`/flashcards/${id}/qa`, {
      isQAed,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const createFlashcardVariant = async (
  flashcardId: string,
  data: FlashcardUpdateData
) => {
  try {
    const response = await axiosInstance.post(
      `/flashcards/${flashcardId}/variant`,
      data
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
