import React from 'react';
import AppBar from '../Global/AppBar';
import { X } from 'react-feather';
import { hapticsImpactLight } from '../../utils/haptics';
import { useNavigate } from 'react-router-dom';

interface PageProps {
  type?: string;
}

const Page: React.FC<PageProps & { children: React.ReactNode }> = ({
  type,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <div className={`page ${type ? 'page--' + type : ''}`}>
      <button
        className="button button--close"
        onClick={() => {
          hapticsImpactLight();
          navigate('/');
        }}
      >
        <X />
      </button>
      <div className="page_content">{children}</div>
      <AppBar />
    </div>
  );
};

export default Page;
