import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Filter, Home, Layers, List, X } from 'react-feather';
import { RootState } from '../../../store/store';
import { StudyPhase } from '../../../types/Study';
import { HeaderType, SecondaryScreen } from '../../../types/Header';
import Tooltip from '@mui/material/Tooltip';
import StudyProgress from './StudyProgress';
import { hapticsImpactLight } from '../../../utils/haptics';
import { setUserInfo } from '../../../slices/auth/authSlice';
import Select from '@mui/material/Select';
import { UserExam } from '../../../types/User';
import MenuItem from '@mui/material/MenuItem';
import { fetchExams } from '../../../services/exams';
import {
  addOrUpdateUserExam,
  changePrimaryExam,
} from '../../../services/users';
import ListSubheader from '@mui/material/ListSubheader';
import { useSnackbar } from 'notistack';
import {
  defaultFlashcards,
  defaultVignettes,
} from '../../../utils/contentQuantityUtils';
import { TopicTreeNode } from '../../../types/TopicCustomization';
import moment from 'moment';
import { calcDefaultExamDate } from '../../../helpers/userHelpers';
import RegenerateModal from '../RegenerateModal';
// import ActiveTopicFlyout from './ActiveTopicFlyout';
import OraLogo from '../OraLogo';
import WorkloadSelect from './WorkloadSelect';
import ExamDateSelect from './ExamDateSelect';

interface HeaderProps {
  type: HeaderType;
  isDiagnostic: boolean;
  changePhase?: (phase: StudyPhase, options?: object) => void;
  setIsSwitchingExam?: (isSwitchingExam: boolean) => void;
  secondaryScreenType?: SecondaryScreen | null | undefined;
  secondaryScreenIsOpen?: boolean;
  closeSecondaryScreen?: () => void;
  openTopicFilters?: () => void;
  unsavedChanges?: boolean;
  handleSaveTopicUpdates?: (
    topicFilters: TopicTreeNode[],
    regenerate: boolean
  ) => void;
  activeTopicsChanged?: boolean;
  setIsLoadingStudySessionAndContent?: (isLoading: boolean) => void;
  setIsLoadedStudySessionAndContent?: (isLoaded: boolean) => void;
  refreshStudySessionAndContent?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  type,
  isDiagnostic,
  changePhase,
  setIsSwitchingExam,
  secondaryScreenType,
  secondaryScreenIsOpen,
  closeSecondaryScreen,
  openTopicFilters,
  handleSaveTopicUpdates,
  activeTopicsChanged,
  setIsLoadingStudySessionAndContent,
  setIsLoadedStudySessionAndContent,
  refreshStudySessionAndContent,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.userInfo);
  const todaySession = useSelector((state: RootState) => state.todaySession);
  const topicTree = useSelector(
    (state: RootState) => state.activeTopics.topicTree
  );
  const allTopicsActive = useSelector(
    (state: RootState) => state.activeTopics.allTopicsActive
  );
  const topicFilters =
    useSelector((state: RootState) => state.activeTopics.topicTree) || [];
  const allTopicsInactive = useSelector(
    (state: RootState) => state.activeTopics.allTopicsInactive
  );
  const flashcards = useSelector((state: RootState) => state.flashcards);
  const blocks = useSelector((state: RootState) => state.blocks);

  const currentUserExams =
    useSelector((state: RootState) => state.auth.userInfo?.exams) || [];
  const [availableExams, setAvailableExams] = useState<UserExam[] | []>([]);
  const primaryExam = currentUserExams.find((exam) => exam.primary);
  const [examSelection, setExamSelection] = useState(primaryExam?.id || '');
  const [sortedExams, setSortedExams] = useState<UserExam[] | []>([]);
  const [showRegenModal, setShowRegenModal] = useState(false);
  const [topicCount, setTopicCount] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (currentUser) {
      getAvailableExams();
      setSortedExams(prioritizeExams());
    }
  }, [currentUser]);

  useEffect(() => {
    setSortedExams(prioritizeExams());
  }, [availableExams, currentUser]);

  useEffect(() => {
    if (examSelection && examSelection !== primaryExam?.id) {
      if (setIsSwitchingExam) {
        setIsSwitchingExam(true);
      }
      assignExamToUser();
    }
  }, [examSelection]);

  // Determine the number of active topics
  useEffect(() => {
    renderActiveTopicCount();
  }, [topicFilters, allTopicsActive, allTopicsInactive]);

  const getAvailableExams = async () => {
    const response = await fetchExams();
    if (response) {
      setAvailableExams(response);
    }
  };

  const allBlocksSubmitted = () => {
    let allBlocksSubmitted = true;
    blocks.forEach((block) => {
      if (!block.isSubmitted) {
        allBlocksSubmitted = false;
      }
    });
    return allBlocksSubmitted;
  };

  const renderFlashcardButton = () => {
    if (!todaySession?.flashcardCount || todaySession?.isReview) return null;
    if (flashcards.new.length + flashcards.due.length === 0) {
      return (
        <Tooltip title="No flashcards remaining." enterTouchDelay={0}>
          <span>
            <button
              className="button button--glass button--phase"
              onClick={() => {
                hapticsImpactLight();
              }}
              disabled
            >
              <Check />
              <span>Flashcards</span>
            </button>
          </span>
        </Tooltip>
      );
    } else {
      if (changePhase) {
        return (
          <button
            className="button button--glass button--phase"
            onClick={() => {
              changePhase(StudyPhase.flashcard);
              localStorage.removeItem('ora-today-block-id');
              hapticsImpactLight();
            }}
          >
            <Layers />
            <span>Flashcards</span>
          </button>
        );
      }
    }
  };

  const prioritizeExams = () => {
    if (!currentUserExams.length || !availableExams.length) return [];
    const userExams = [...currentUserExams];
    const remainingExams = availableExams.filter(
      (exam) => !userExams.some((userExam) => userExam.id === exam.id)
    );
    const stepExams = remainingExams.filter(
      (exam) => exam.name === 'USMLE Step 1' || exam.name === 'USMLE Step 2'
    );
    const otherExams = remainingExams.filter(
      (exam) => exam.name !== 'USMLE Step 1' && exam.name !== 'USMLE Step 2'
    );

    userExams.sort((a, b) => a.name.localeCompare(b.name));
    stepExams.sort((a, b) => a.name.localeCompare(b.name));
    otherExams.sort((a, b) => a.name.localeCompare(b.name));

    return [...userExams, ...stepExams, ...otherExams];
  };

  const assignExamToUser = async () => {
    if (!currentUser || !refreshStudySessionAndContent) return;
    const existingExam = currentUserExams.find(
      (exam) => exam.id === examSelection
    );
    if (existingExam) {
      const response = await changePrimaryExam(currentUser.id, examSelection);
      const updatedUser = { ...currentUser };
      updatedUser.exams = response;
      dispatch(setUserInfo(updatedUser));
    } else {
      const newExamName = availableExams.find(
        (exam) => exam.id === examSelection
      )?.name;
      if (newExamName) {
        const response = await addOrUpdateUserExam(
          currentUser.id,
          examSelection,
          moment(calcDefaultExamDate(newExamName)).toISOString(),
          defaultFlashcards(newExamName),
          defaultVignettes(newExamName),
          true,
          true,
          false,
          null,
          false
        );
        const updatedUser = { ...currentUser };
        updatedUser.exams = response;
        dispatch(setUserInfo(updatedUser));
        enqueueSnackbar(newExamName + ' added.', { autoHideDuration: 3000 });
      }
    }

    // Refresh study session and content
    await refreshStudySessionAndContent();
    if (setIsSwitchingExam) {
      setIsSwitchingExam(false);
    }
  };

  // const renderActiveTopics = () => {
  //   if (!allTopicsActive) {
  //     const activeTopics = topicTree.filter((topic) => topic.active);
  //     const activeTopicCounts = activeTopics.map((topic) => {
  //       if (!topic.children) return [];
  //       return topic.children.filter((child) => child.active);
  //     });
  //     const activeTopicCountsCombined = activeTopics.map((topic, index) => {
  //       return { ...topic, activeChildren: activeTopicCounts[index] };
  //     });

  //     return (
  //       <div
  //         className="active-filters"
  //         onClick={() => {
  //           if (openTopicFilters) {
  //             openTopicFilters();
  //           }
  //         }}
  //       >
  //         {activeTopicCountsCombined.map((topic) => (
  //           <ActiveTopicFlyout key={topic.id} topic={topic} />
  //         ))}
  //       </div>
  //     );
  //   }
  // };

  const renderActiveTopicCount = () => {
    if (!allTopicsActive) {
      const activeTopics = topicTree.filter((topic) => topic.active);
      const activeTopicCounts = activeTopics.map((topic) => {
        if (!topic.children) return [];
        return topic.children.filter((child) => child.active);
      });
      const activeTopicCountsCombined = activeTopics.map((topic, index) => {
        return { ...topic, activeChildren: activeTopicCounts[index] };
      });
      setTopicCount(activeTopicCountsCombined.length);
    }
  };

  const handleCloseButtonClick = async () => {
    if (
      secondaryScreenType &&
      secondaryScreenType === SecondaryScreen.TopicFilters
    ) {
      if (activeTopicsChanged) {
        if (todaySession.isStarted && !todaySession.isComplete) {
          setShowRegenModal(true);
        } else {
          submitTopicChanges(true);
          if (closeSecondaryScreen) {
            closeSecondaryScreen();
          }
        }
      } else {
        if (closeSecondaryScreen) {
          closeSecondaryScreen();
        }
      }
    } else {
      if (closeSecondaryScreen) {
        closeSecondaryScreen();
      }
    }

    hapticsImpactLight();
  };

  const disableCloseButton = () => {
    if (
      secondaryScreenType &&
      secondaryScreenType === SecondaryScreen.TopicFilters &&
      allTopicsInactive
    ) {
      return true;
    } else {
      return false;
    }
  };

  const menuProps = {
    PaperProps: {
      style: {
        WebkitBackdropFilter: 'blur(20px)',
        backdropFilter: 'blur(20px)',
        backgroundColor: 'rgba(0,0,0,.6)',
        color: 'var(--type-body-inverse)',
        border: '0 !important',
      },
    },
  };

  const submitTopicChanges = (regenerate: boolean) => {
    if (handleSaveTopicUpdates) {
      handleSaveTopicUpdates(topicFilters, regenerate);
    }
  };

  const renderSecondaryScreenHeader = (
    secondaryScreenType: SecondaryScreen | null | undefined
  ) => {
    if (secondaryScreenType !== null && secondaryScreenType !== undefined) {
      switch (secondaryScreenType) {
        case SecondaryScreen.TopicFilters:
          return (
            <div className="exam-title">
              <span>{primaryExam?.name}</span>
              <h1>Active topics</h1>
            </div>
          );
        default:
          return '';
      }
    }
    return '';
  };

  const renderOraLogo = () => {
    if (type === 'study') return;
    if (currentUser && currentUser.id) {
      return <OraLogo />;
    } else {
      return <OraLogo type="link" url={'https://www.oraai.com'} />;
    }
  };

  return (
    <div
      className={`header ${type ? 'header--' + type : ''} ${secondaryScreenIsOpen ? 'header--secondary-screen' : ''} ${secondaryScreenIsOpen && secondaryScreenType === SecondaryScreen.ExamCompletion ? 'header--exam-completion' : ''}`}
    >
      {!isDiagnostic && (
        <Tooltip
          title={
            type === 'study' &&
            (todaySession.phase === StudyPhase.flashcard ||
              todaySession.phase === StudyPhase.qbank)
              ? 'Your progress will be saved.'
              : ''
          }
          enterTouchDelay={0}
        >
          <button
            role="link"
            className={
              type === 'study' ? 'button button--glass' : 'button--ora'
            }
            onClick={() => {
              navigate('/');
              hapticsImpactLight();
            }}
          >
            {renderOraLogo()}
            {type === 'study' &&
              (todaySession.phase === StudyPhase.qbankReview ||
                todaySession.phase === StudyPhase.sessionReview) && (
                <>
                  <Home />
                  <span>Home</span>
                </>
              )}
            {type === 'study' &&
              (todaySession.phase === StudyPhase.flashcard ||
                todaySession.phase === StudyPhase.qbank) && (
                <>
                  <Home />
                  <span>Home</span>
                </>
              )}
          </button>
        </Tooltip>
      )}
      {type === 'study' && (
        <div className="study-middle">
          {todaySession.phase === StudyPhase.flashcard && <StudyProgress />}
        </div>
      )}
      {type === 'study' && changePhase && (
        <>
          {todaySession.phase === StudyPhase.flashcard &&
            todaySession?.vignetteCount > 0 && (
              <button
                className="button button--glass button--phase"
                onClick={() => {
                  changePhase(StudyPhase.qbank);
                  hapticsImpactLight();
                }}
              >
                {allBlocksSubmitted() ? <Check /> : <List />}
                <span>QBank</span>
                {/* {renderQBankTip()} */}
              </button>
            )}
          {todaySession.phase === StudyPhase.qbank && renderFlashcardButton()}
        </>
      )}
      {secondaryScreenIsOpen &&
        secondaryScreenType !== SecondaryScreen.ExamCompletion && (
          <>
            {renderSecondaryScreenHeader(secondaryScreenType)}
            <Tooltip
              title={
                disableCloseButton()
                  ? 'You must have at least one active topic.'
                  : ''
              }
              enterTouchDelay={0}
            >
              <span>
                <button
                  onClick={handleCloseButtonClick}
                  className="button button--close button--icon-only button--glass--link"
                  disabled={disableCloseButton()}
                >
                  <X />
                </button>
              </span>
            </Tooltip>
          </>
        )}
      {type === HeaderType.home && !secondaryScreenIsOpen && (
        <>
          <div className="exam-selector">
            <div className="exam-selector_top">
              <button
                className={`button button--glass ${!allTopicsActive ? 'fill' : ''}`}
                onClick={() => {
                  if (openTopicFilters) {
                    openTopicFilters();
                  }
                  hapticsImpactLight();
                }}
              >
                <Filter />
                {topicCount > 0 && !allTopicsActive && (
                  <span
                    style={{
                      color: 'var(--type-body-inverse)',
                    }}
                  >
                    {topicCount}
                  </span>
                )}
              </button>
              <Select
                labelId="exam-selector-label"
                id="exam-selector"
                value={examSelection}
                fullWidth
                onChange={(event) => {
                  const newValue = event.target.value;
                  setExamSelection(newValue);
                }}
                onClick={() => {
                  hapticsImpactLight();
                }}
                MenuProps={menuProps}
              >
                {sortedExams.reduce<React.ReactNode[]>((acc, exam, index) => {
                  acc.push(
                    <MenuItem
                      key={exam.id}
                      value={exam.id}
                      onClick={() => {
                        hapticsImpactLight();
                      }}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        },
                        '&.Mui-selected:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, .1)',
                        },
                      }}
                    >
                      {exam.name}
                    </MenuItem>
                  );
                  if (
                    index === currentUserExams.length - 1 &&
                    availableExams.length > currentUserExams.length
                  ) {
                    acc.push(
                      <ListSubheader key="add-an-exam">
                        Add an Exam
                      </ListSubheader>
                    );
                  }
                  return acc;
                }, [])}
              </Select>
            </div>
            {setIsLoadingStudySessionAndContent &&
              setIsLoadedStudySessionAndContent &&
              refreshStudySessionAndContent && (
                <div className="exam-selector_bottom">
                  <ExamDateSelect
                    setIsLoadingStudySessionAndContent={
                      setIsLoadingStudySessionAndContent
                    }
                    setIsLoadedStudySessionAndContent={
                      setIsLoadedStudySessionAndContent
                    }
                    refreshStudySessionAndContent={
                      refreshStudySessionAndContent
                    }
                  />
                  <WorkloadSelect
                    setIsLoadingStudySessionAndContent={
                      setIsLoadingStudySessionAndContent
                    }
                    setIsLoadedStudySessionAndContent={
                      setIsLoadedStudySessionAndContent
                    }
                    refreshStudySessionAndContent={
                      refreshStudySessionAndContent
                    }
                  />
                </div>
              )}
          </div>
        </>
      )}
      <RegenerateModal
        showRegenModal={showRegenModal}
        setShowRegenModal={setShowRegenModal}
        submitChanges={submitTopicChanges}
        type={'exam-settings'}
      />
    </div>
  );
};

export default Header;
